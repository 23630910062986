import { useMutation, MutationResultPair, queryCache } from 'react-query';
import { AccountFormInputs } from 'types/accounts';
import { getAccount, saveAccounts } from 'utils/urls';
import { getTokenWithType } from './useAuth';

export async function postAccountInfo({
  params,
}: {
  params: AccountFormInputs;
}): Promise<TResult> {
  const response = await fetch(
    params.Id === 0 || params.Id === null
      ? saveAccounts
      : getAccount(params.Id),
    {
      method: params.Id === 0 ? 'POST' : 'PUT',
      headers: {
        Authorization: getTokenWithType(),
        'content-type': 'application/json',
      },
      body: JSON.stringify(params),
    },
  );
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = AccountFormInputs & ValidationError & Array<string>;
export type TVariables = AccountFormInputs;
export type TError = string;
export type TSnapshot = unknown;
export type useLoginReturnType = MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
>;

async function wait(millis: number) {
  return new Promise(resolve => {
    setTimeout(resolve, millis);
  });
}

function useSaveAccountInfo(): MutationResultPair<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  return useMutation(
    async (params: AccountFormInputs) => {
      const newAccountInfo = await postAccountInfo({
        params: params,
      });
      if (newAccountInfo.Message) throw newAccountInfo.Message;
      else if (newAccountInfo.length) throw newAccountInfo[0];
      else if (newAccountInfo.Id)
        if (params.Id !== 0) {
          queryCache.invalidateQueries(['get_account', params.Id]);
          queryCache.invalidateQueries(['get_twilio_info', params.Id]);
          await wait(2000);
        }

      return newAccountInfo;
    },
    {
      throwOnError: true,
    },
  );
}

export { useSaveAccountInfo };
