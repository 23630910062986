import { createGlobalStyle } from 'styled-components';
import { getHSL } from 'utils/getHSL';
import theme from './theme';

type ThemeType = typeof theme;

const GlobalStyles = createGlobalStyle<{ theme: ThemeType }>`

    /* -------------------------- */
    /* --------   Reset  -------- */
    /* -------------------------- */

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    ul[class],
    ol[class] {
        padding: 0;
    }

    body,
    h1,
    h2,
    h3,
    h4,
    p,
    ul[class],
    ol[class],
    li,
    figure,
    figcaption,
    blockquote,
    dl,
    dd {
        margin: 0;
    }

		html {
			height: 100%;
		}

    body {
    height: 100%;
    scroll-behavior: smooth;
    text-rendering: optimizeLegibility;
        line-height: 1.5;
    }

    ul[class],
    ol[class] {
        list-style: none;
    }

    a:not([class]) {
        text-decoration-skip-ink: auto;
    }

    img {
    max-width: 100%;
        display: block;
    }

    article > * + * {
        margin-top: 1em;
    }

    input,
    button,
    textarea,
    select {
        font: inherit;
    }

     /* -------------------------- */
    /* -------   Configs  ------- */
    /* -------------------------- */

    :root {
        font-size: 14px;
        --contrast-threshold: 60%;
        --focus-outline-opacity: 50%;
        --input-disabled-opacity:0.65;
      }

    /* ---------- Colors --------- */
    :root {
        /* ------- Primary color ------- */
        --primary-h: ${({ theme }) => getHSL('h', theme.colors.primary)};
        --primary-s: ${({ theme }) => getHSL('s', theme.colors.primary)};
        --primary-l: ${({ theme }) => getHSL('l', theme.colors.primary)};
        --primary-color-hsl: var(--primary-h), var(--primary-s), var(--primary-l);
        --primary-color: hsl(var(--primary-h), var(--primary-s), var(--primary-l));
        --color-switch-on-primary: calc((var(--primary-l) - var(--contrast-threshold)) * -100);
        --color-on-primary: hsl(0, 0%, var(--color-switch-on-primary));

				/* ------- State colors ------- */
				--color-state-error: ${({ theme }) => theme.colors.state.error};
				--color-state-warning: ${({ theme }) => theme.colors.state.warning};
				--color-state-success: ${({ theme }) => theme.colors.state.success};
				--color-state-info: ${({ theme }) => theme.colors.state.info};

        /* ------- Neutral shades that change based on color mode ------- */
        --color-neutral-contrast-00: ${({ theme }) => theme.colors.white};
        --color-neutral-contrast-01: ${({ theme }) => theme.baseColors.gray[0]};
        --color-neutral-contrast-02: ${({ theme }) => theme.baseColors.gray[1]};
        --color-neutral-contrast-03: ${({ theme }) => theme.baseColors.gray[2]};
        --color-neutral-contrast-04: ${({ theme }) => theme.baseColors.gray[3]};
        --color-neutral-contrast-05: ${({ theme }) => theme.baseColors.gray[4]};
        --color-neutral-contrast-06: ${({ theme }) => theme.baseColors.gray[5]};
        --color-neutral-contrast-07: ${({ theme }) => theme.baseColors.gray[6]};
        --color-neutral-contrast-08: ${({ theme }) => theme.baseColors.gray[7]};
        --color-neutral-contrast-09: ${({ theme }) => theme.baseColors.gray[8]};
        --color-neutral-contrast-10: ${({ theme }) => theme.baseColors.gray[9]};

        /* ------- Neutral shades HSL ------- */
        --color-neutral-contrast-03-h:
        ${({ theme }) => getHSL('h', theme.baseColors.gray[2])};
        --color-neutral-contrast-03-s:
        ${({ theme }) => getHSL('s', theme.baseColors.gray[2])};
        --color-neutral-contrast-03-l:
        ${({ theme }) => getHSL('l', theme.baseColors.gray[2])};
        --color-neutral-contrast-03-hsl: var(--color-neutral-contrast-03-h), var(--color-neutral-contrast-03-s), var(--color-neutral-contrast-03-l);

        --color-neutral-contrast-08-h:
        ${({ theme }) => getHSL('h', theme.baseColors.gray[7])};
        --color-neutral-contrast-08-s:
        ${({ theme }) => getHSL('s', theme.baseColors.gray[7])};
        --color-neutral-contrast-08-l:
        ${({ theme }) => getHSL('l', theme.baseColors.gray[7])};
        --color-neutral-contrast-08-hsl: var(--color-neutral-contrast-08-h), var(--color-neutral-contrast-08-s), var(--color-neutral-contrast-08-l);


        --color-body-text: var(--color-neutral-contrast-09);
      	--color-borer-default: var(--color-neutral-contrast-03);
    }

    /*----------- Dark Mode -----------*/
    html[data-color-mode='dark'] {
      --color-neutral-contrast-00: ${({ theme }) => theme.colors.black};
      --color-neutral-contrast-01: ${({ theme }) => theme.baseColors.gray[9]};
      --color-neutral-contrast-02: ${({ theme }) => theme.baseColors.gray[8]};
      --color-neutral-contrast-03: ${({ theme }) => theme.baseColors.gray[7]};
      --color-neutral-contrast-04: ${({ theme }) => theme.baseColors.gray[6]};
      --color-neutral-contrast-05: ${({ theme }) => theme.baseColors.gray[5]};
      --color-neutral-contrast-06: ${({ theme }) => theme.baseColors.gray[4]};
      --color-neutral-contrast-07: ${({ theme }) => theme.baseColors.gray[3]};
      --color-neutral-contrast-08: ${({ theme }) => theme.baseColors.gray[2]};
      --color-neutral-contrast-09: ${({ theme }) => theme.baseColors.gray[1]};
      --color-neutral-contrast-10: ${({ theme }) => theme.baseColors.gray[0]};

      /* ------- Neutral shades HSL ------- */
      --color-neutral-contrast-03-h:
      ${({ theme }) => getHSL('h', theme.baseColors.gray[7])};
      --color-neutral-contrast-03-s:
      ${({ theme }) => getHSL('s', theme.baseColors.gray[7])};
      --color-neutral-contrast-03-l:
      ${({ theme }) => getHSL('l', theme.baseColors.gray[7])};
      --color-neutral-contrast-03-hsl: var(--color-neutral-contrast-03-h), var(--color-neutral-contrast-03-s), var(--color-neutral-contrast-03-l);

      --color-neutral-contrast-08-h:
      ${({ theme }) => getHSL('h', theme.baseColors.gray[2])};
      --color-neutral-contrast-08-s:
      ${({ theme }) => getHSL('s', theme.baseColors.gray[2])};
      --color-neutral-contrast-08-l:
      ${({ theme }) => getHSL('l', theme.baseColors.gray[2])};
      --color-neutral-contrast-08-hsl: var(--color-neutral-contrast-08-h), var(--color-neutral-contrast-08-s), var(--color-neutral-contrast-08-l);

      --color-body-text: var(--color-neutral-contrast-07);
      --color-borer-default: var(--color-neutral-contrast-02);
  }

  html.color-mode-in-transition,
  html.color-mode-in-transition *,
  html.color-mode-in-transition *:before,
  html.color-mode-in-transition *:after {
      transition: all 750ms !important;
      transition-delay: 0 !important;
  }





    /* -------------------------------- */
    /* -------   Global Styles  ------- */
    /* -------------------------------- */

    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background-color: var(--low-contrast-neutral);
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 2px;
        /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.1); */
        background-color: #999;
    }

    ::-webkit-scrollbar-thumb:focus {
      background-color: #888;
    }

    ::-webkit-scrollbar-thumb:active {
      background-color: #777;
    }

    ::-webkit-scrollbar-track {
        border-radius: 2px;
        box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
        background-color: var(--low-contrast-neutral);
    }

    body {
        --bg: hsla(var(--color-neutral-contrast-03-hsl), 30%);
        background-color: var(--bg);
        font-family: ${({ theme }) => theme.fonts.body};
        color: ${({ theme }) => theme.colors.text.body};
    }

    html[data-color-mode='dark'] {
      body {
        --bg: var(--color-neutral-contrast-00);
      }
    }

    #modal {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1050;
    }

    #modal:empty {
      display: none;
    }

    .pr-modal-wrapper {
      position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    #root {
        height: 100%;
        scroll-behavior: smooth;
        display: flex;
        flex-direction: column;
    }

    .under-construction {
        max-width: 150px;
        margin-bottom: 30px;
    }

    .sr-only {
        border: 0;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .fade-in-up {
        animation: fadeInUp 1000ms both;
    }

    .fade-in {
        animation: fadeIn 1000ms both;
    }

    @keyframes fadeInUp {
        0% {
            opacity: 0;
            transform: translate3d(0,100%,0);
        }
        100% {
            opacity: 1;
            transform: none;
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .pr-btn-primary {
      --h: var(--primary-h);
      --s: var(--primary-s);
      --l: var(--primary-l);

      --switch: calc((var(--l) - var(--contrast-threshold)) * -100);

      display: inline-flex;
      align-items: center;
      appearance: none;
      padding: 0.571em 0.8em;
      font-family: inherit;
      font-size: ${({ theme }) => theme.fontSizes[2]};
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      line-height: 1;
      text-align: center;
      color: hsl(0, 0%, var(--switch));
      background: hsl(var(--h), var(--s), var(--l)) !important;
      border: solid 1px transparent;
      border-radius: ${({ theme }) => theme.radii.rounded};
      cursor: pointer;
      /* transition: background 200ms ease-in-out; */
      /* text-shadow: 1px 1px 1px rgba(0,0,0,0.1); */

      &:hover,
      &:active {
        background: hsl(var(--h), var(--s), calc(var(--l) - 10%)) !important;
      }

      &:focus {
        outline: none;
        background: hsl(var(--h), var(--s), calc(var(--l) - 10%));
        border: 1px solid hsl(var(--h), var(--s), calc(var(--l) - 15%));
        box-shadow: 0 0 0 0.2rem hsla(var(--h), var(--s), var(--l), 60%);
      }
    }

    .react-confirm-alert-overlay {
        z-index: 1060;
    }


    .pb-cb__link-account  {
			input[type='checkbox'] + label::before {
				margin-right: ${({ theme }) => theme.space[3]};
			}

			label >	span {
				overflow-x: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				max-width: 240px;
				font-weight: ${({ theme }) => theme.fontWeights.medium};
			}
		}

		.outer-pages-link, .outer-pages-link:visited {
			display: inline-block;
			color: ${({ theme }) => theme.colors.primary};
			/* transform: none; */

			&:active {
				transform: translate3d(0,1px,0);
			}
		}

		.truncate {
			white-space: nowrap;
			overflow-x: hidden;
			text-overflow: ellipsis;
		}


	


		.react-confirm-alert-overlay {
			z-index: 2000;
		}

		.slidePanel-dragFix {
			/* top:0 !important; */
			left: auto !important;
		}

		/* Tippy Customization -- Start */
			.tippy-popper .tippy-tooltip.light-theme[data-animatefill],
			.tippy-popper[x-placement^=left] .tippy-tooltip.light-theme [x-circle] {
				background-color: var(--color-neutral-contrast-00);

				html[data-color-mode='dark'] & {
					background-color: var(--color-neutral-contrast-01);
				}
			}
		/* Tippy Customization -- End */

		/* toastify */
		.Toastify {
				position: relative;
				z-index: 1000001;
				isolation: isolate;
		}

	.pc-section-item-questions {
		background: ${({ theme }) => theme.colors.transparent.white50};
		html[data-color-mode='dark'] & {
			background-color: ${({ theme }) => theme.colors.transparent.black50};
		}
	}

	.pc-section-item-accordion-body {
		background: var(--color-neutral-contrast-00);
		html[data-color-mode='dark'] & {
			background-color: var(--color-neutral-contrast-00);
		}
	}

/* Accessibe - Start */
	/* .acsb-trigger.acsb-trigger.acsb-trigger {
		z-index: 210 !important;
	} */

	.acsb-ff-wrapper.acsb-ff-wrapper.acsb-ff-wrapper.acsb-ff-wrapper {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.acsb-emphasize-links.acsb-emphasize-titles .acsb-ff-wrapper {
		height: 96%;
	}

	.acsb-vision-profile .acsb-ff-wrapper.acsb-ff-wrapper.acsb-ff-wrapper.acsb-ff-wrapper {
		width: 86.3333% !important;
		height: 86.3333%;
		overflow: hidden;
	}

	body.acsb-stop-animations>*:not(.acsb-widget) * {
    transition: none !important;
    animation-fill-mode: forwards !important;
    animation-iteration-count: 1 !important;
    animation-duration: .01s !important;
	}

	.acsb-stop-animations .Toastify__toast.Toastify__toast.Toastify__toast.Toastify__toast {
		animation-name: fadeIn !important;
		animation-duration:  750ms !important;
	}

	.acsb-widget.acsb-widget.acsb-widget {
		max-height: 90%;
	}
	/* Accessibe - End */

	/* React Select Customization - Start */
	 .pr-select__menu {
		background-color: var(--color-neutral-contrast-00);

		html[data-color-mode='dark'] & {
			background-color: var(--color-neutral-contrast-02);
		}
	}

	.pr-select__control.pr-select__control {
  --input-bg: hsla(var(--color-neutral-contrast-03-hsl), 50%);
		border-color: ${({ theme }) => theme.colors.border.default};
    background-color: var(--input-bg);

    html[data-color-mode='dark'] & {
    --input-bg: var(--color-neutral-contrast-02);
  }

  &--is-focused {
    background-color: var(--color-neutral-contrast-00);
    border-color: var(--primary-color);
    outline: 0;
    box-shadow: 0 0 0 0.2rem hsla(var(--h), var(--s), var(--l), 30%);

    html[data-color-mode='dark'] & {
      background-color: var(--color-neutral-contrast-00);
    }
  }
	}

	.pr-select__control:hover.pr-select__control:hover {
		border-color: var(--color-neutral-contrast-03);
	}

	.pr-select__single-value:not(.pr-select--is-disabled .pr-select__single-value).pr-select__single-value:not(.pr-select--is-disabled .pr-select__single-value) {
		color: ${({ theme }) => theme.colors.text.body};
 
	}

	.pr-select__control--is-focused.pr-select__control--is-focused,
	.pr-select__control--is-focused:hover.pr-select__control--is-focused:hover,
	.pr-select__control--is-focused:active.pr-select__control--is-focused:active {
		border-color: ${({ theme }) => theme.colors.primary};
		box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.primary};
	}

	.pr-select__control--is-focused.pr-select__control--is-focused .pr-select__control.pr-select__control {
		html[data-color-mode='dark'] & {
			color: var(--color-neutral-contrast-00);
		}
	}

	.pr-select__option--is-focused.pr-select__option--is-focused {
		background-color: var(--color-neutral-contrast-03);
	}

	.pr-select__option:active.pr-select__option:active {
		background-color: var(--color-neutral-contrast-05);
	}

	.pr-select__option--is-selected.pr-select__option--is-selected {
		background-color: ${({ theme }) => theme.colors.primary};
	}

	.pr-select--question-type .pr-select__menu .pr-select__option .label > span:first-of-type {
		font-weight: 600;
	}

	.pr-select--question-type .pr-select__menu .pr-select__option {
		border-bottom: solid 1px ${({ theme }) => theme.colors.border.default};

		html[data-color-mode='dark'] & {
            border-color: var(--color-neutral-contrast-01);
        }
	}

    .pr-select--is-disabled {
        opacity: 0.75;

        html[data-color-mode='dark'] & {
            .pr-select__indicators, .pr-select__indicator-separator {
                opacity: 0.5;
            }

        }

    }
	/* React Select Customization - End */


	/* Masked Input Customization - Start */

	.pr-masked-input-wrapper {
		--px: 0.571em;
		--py: 0.571em;
		--a: 80%;
		--h: var(--primary-h);
		--s: var(--primary-s);
		--l: var(--primary-l);
		--input-bg: hsla(var(--color-neutral-contrast-03-hsl), 50%);
		position: relative;
		width: 100%;
	}

	.pr-masked-input {
		display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: var(--py) var(--px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.text.body};
  background-color: var(--input-bg);
  background-clip: padding-box;
  border: 1px solid ${({ theme }) => theme.colors.border.default};
  border-radius: ${({ theme }) => theme.radii.rounded};
  transition: all 200ms ease-in-out;

  html[data-color-mode='dark'] & {
    --input-bg: var(--color-neutral-contrast-02);
  }

  &:focus {
    background-color: var(--color-neutral-contrast-00);
    border-color: var(--primary-color);
    outline: 0;
    box-shadow: 0 0 0 0.2rem hsla(var(--h), var(--s), var(--l), 30%);

    html[data-color-mode='dark'] & {
      background-color: var(--color-neutral-contrast-00);
    }
  }

  &:disabled {
    opacity: var(--input-disabled-opacity);
    cursor: not-allowed;

  }

  html[data-color-mode='dark'] & {
    --a: 40%;

    ::placeholder {
      color: var(--reduced-high-contrast-neutral);
    }
  }
	}

	/* Masked Input Customization - End */


	/* Country flag selection Customization - Start */

	.pr-ReactSelectWithFlags {
		min-width: 130px;

		.pr-select__indicator.pr-select__dropdown-indicator {
			padding: 4px;
		}

		.pr-select__input-container {
			padding: 0;
			margin: 0;
		}

		.pr-select__control {
			min-height: 34px;
		}

		.pr-select__menu {
			min-width: 250px;
		}
	}

	/* Country flag selection Customization - End */

    .PhoneNumberChangeAlertWrapper--firstchild {
    top: auto;
    bottom: 0;
    z-index: 2;
    transform: translateY(calc(100% + 4px));
  }
`;

export default GlobalStyles;
