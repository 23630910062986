import React, { ReactElement, useEffect, useState } from 'react';
import { confirmAlert } from 'utils/confirm-alert';
import { Flex } from 'rebass/styled-components';
import {
  useRouteMatch,
  useHistory,
  Route,
  Switch,
  useParams,
} from 'react-router-dom';
import { MainContent, PageHeader, PageContent, VerticalNav } from 'ui/patterns';
import { useDocumentTitle, useGetAccountInfo } from 'hooks';
import AccountInformation from './AccountInformation';
import OfficeFacts from './OfficeFacts';
import LocationList from './LocationList';
import ContactList from './ContactList';
import CustomTables from './CustomTables';
import FTPSettings from './FTPSettings';
import XMLTagMapping from './XMLTagMapping';
import * as Icon from '../../assets/icons';
import ImportAccount from '../ImportAccount';
import ProcedureBuilder from '../ProcedureBuilder/index';
import FormList from '../FormsList/index';
import AddEditForm from 'containers/AddEditForm';
import Telephony from './Telephony';

const tabs = [
  {
    id: 'accountInfo',
    label: 'Account Info',
    icon: <Icon.LgBriefcase />,
  },
  {
    id: 'officeFacts',
    label: 'Office Facts',
    icon: <Icon.LgClipboardCheck />,
  },
  {
    id: 'locationList',
    label: 'Location List',
    icon: <Icon.LgLocation />,
  },
  { id: 'contactList', label: 'Contact List', icon: <Icon.ContactList /> },
  { id: 'customTables', label: 'Custom Tables', icon: <Icon.LgTable /> },
  { id: 'XMLTagMapping', label: 'XML Tag Mapping', icon: <Icon.LgTag /> },
  {
    id: 'FTPSettings',
    label: 'FTP Settings',
    groupEnd: true,
    icon: <Icon.LgServer />,
  },
  { id: 'FormList', label: 'Form List', icon: <Icon.LgList /> },
  {
    id: 'Procedures',
    label: 'Procedures',
    groupEnd: true,
    icon: <Icon.LgDocument />,
  },
  { id: 'Telephony', label: 'Telephony', icon: <Icon.LgCall /> },
];

function AddEditAccount(): ReactElement {
  const match = useRouteMatch('/home/accounts/add');
  const history = useHistory<{ ID: number; tabId?: string }>();
  // const tabId = history?.location?.state?.tabId;
  const { accountId: passedId, tabId } = useParams<{
    accountId: string;
    tabId?: string;
  }>();
  const accountId = parseInt(passedId);
  const { data, isLoading } = useGetAccountInfo(accountId);
  const accountName = isLoading
    ? 'Processing'
    : `${data?.accountInfoReturn.Name} `;
  const importAccountName = isLoading
    ? 'Processing'
    : `${data?.accountInfoReturn.AccountNameAndNumber} `;

  const subtitle = () => {
    if (tabId === 'accountInfo') return 'Edit Account';
    if (tabId === 'AddForm ') return 'Add Form';
    if (tabId === 'EditForm') return 'Edit Form';
    if (tabId === undefined) return 'Add Account';
  };

  useDocumentTitle(subtitle(), true);
  const pageTitle = `${match ? 'Create New Account' : accountName}`;
  const pageSubTitle =
    !isLoading && !match
      ? `${data?.accountInfoReturn.CID}${'-'}${
          data?.accountInfoReturn.SID
        }${'-'}${data?.accountInfoReturn.PID}`
      : null;

  // useEffect(() => {
  //   if (!match && accountId === undefined) {
  //     history.push('/home');
  //   }
  // });

  // const pageActions = [
  //   { action: 'Procedure', icon: <Icon.Document title="Procedure" /> },
  //   { action: 'Form List', icon: <Icon.List title="List" /> },
  //   { action: 'Import Account', icon: <Icon.Import title="import" /> },
  // ];

  const [showImportAccount, setImportAccount] = useState(false);

  const toggleImportAccount = () => {
    setImportAccount(false);
  };
  let pageActions;
  if (!match) {
    pageActions = [
      {
        action: 'Import Account Data',
        icon: <Icon.Import ariaHidden="true" />,
        handleButtonClick: () => {
          setImportAccount(true);
        },
      },
      // {
      //   action: 'Form List',
      //   icon: <Icon.List title="List" />,
      //   handleButtonClick: () => {
      //     history.push('/home/forms', { ID: accountId });
      //   },
      // },
      // {
      //   action: 'Procedure',
      //   icon: <Icon.Document title="Procedure" />,
      //   handleButtonClick: () => {
      //     history.push('/home/procedure-builder', { ID: accountId });
      //   },
      // },
    ];
  }

  const [activeTab, setActiveTab] = useState(tabId ? tabId : 'accountInfo');
  const [isDirty, setDirty] = useState(false);
  const onNavClick = (tabId: string) => {
    const nextPath = `/home/accounts/edit/${accountId}/${tabId}`;
    if (isDirty) {
      confirmAlert({
        // title: 'Proteus',
        message: 'Changes you made may not be saved.',
        buttons: [
          {
            label: 'Leave',
            onClick: () => {
              // setActiveTab(tabId);
              history.push(nextPath);
              setDirty(false);
            },
            className: 'pr-btn-primary',
          },
          {
            label: 'Cancel',
            onClick: () => {
              //
            },
          },
        ],
      });
    } else history.push(nextPath);
    //setActiveTab(tabId);
  };

  useEffect(() => {
    if (
      tabId === 'Procedures' ||
      tabId === 'FormList' ||
      tabId === 'AddForm' ||
      tabId === 'EditForm'
    ) {
      setCollapseSidenav(true);
    } else if (tabId !== 'EditForm') {
      setFormId(undefined);
    }
  }, [tabId]);

  const [formId, setFormId] = useState<number>();

  function switchTab(tabId: string, formId?: number) {
    // onNavClick(tabId);
    // setActiveTab(tabId);
    setDirty(false);
    setFormId(formId);
    history.push(tabId);
  }

  // const renderTabContents = () => {
  //   switch (activeTab) {
  //     case 'accountInfo':
  //       return <AccountInformation setDirty={setDirty} />;
  //     case 'officeFacts':
  //       return <OfficeFacts setDirty={setDirty} />;
  //     case 'locationList':
  //       return <LocationList />;
  //     case 'contactList':
  //       return <ContactList />;
  //     case 'customTables':
  //       return <CustomTables />;
  //     case 'XMLTagMapping':
  //       return <XMLTagMapping setDirty={setDirty} />;
  //     case 'FTPSettings':
  //       return <FTPSettings setDirty={setDirty} />;
  //     case 'Procedures':
  //       return <ProcedureBuilder m={-4} setDirty={setDirty} />;
  //     case 'FormList':
  //       return (
  //         <FormList
  //           m={-4}
  //           switchTab={switchTab}
  //           pageTitle={pageTitle}
  //           pageSubTitle={pageSubTitle || undefined}
  //         />
  //       );
  //     case 'AddForm':
  //       return <AddEditForm m={-4} switchTab={switchTab} setDirty={setDirty} />;
  //     case 'EditForm':
  //       return (
  //         <AddEditForm
  //           m={-4}
  //           formId={formId}
  //           switchTab={switchTab}
  //           setDirty={setDirty}
  //         />
  //       );
  //     default:
  //       return <UnderConstructionPage />;
  //   }
  // };

  const [collapseSidenav, setCollapseSidenav] = useState(false);
  const toggleCollapseSidenav = () => setCollapseSidenav(!collapseSidenav);

  return (
    <>
      <MainContent flexDirection="row">
        {!match ? (
          <VerticalNav
            tabs={tabs}
            activeTab={tabId || ''}
            onNavClick={onNavClick}
            collapsed={collapseSidenav}
            toggleCollapse={toggleCollapseSidenav}
          />
        ) : null}

        <Flex flexGrow={1} flexDirection="column">
          {tabId === 'Procedures' ||
          tabId === 'FormList' ||
          tabId === 'AddForm' ||
          tabId === 'EditForm' ||
          tabId === 'Telephony' ? null : (
            <PageHeader
              title={pageTitle}
              subTitle={pageSubTitle}
              actions={pageActions}
            />
          )}
          <Switch>
            {/* <PageContent>{renderTabContents()}</PageContent> */}
            <PageContent>
              <Route
                path={`/home/accounts/add`}
                component={AccountInformation}
                exact
              />
              <Route
                path={`/home/accounts/edit/:accountId/accountInfo`}
                component={AccountInformation}
                exact
              />
              <Route
                path={`/home/accounts/edit/:accountId/officeFacts`}
                component={OfficeFacts}
                exact
              />
              <Route
                path={`/home/accounts/edit/:accountId/locationList`}
                component={LocationList}
                exact
              />
              <Route
                path={`/home/accounts/edit/:accountId/contactList`}
                component={ContactList}
                exact
              />
              <Route
                path={`/home/accounts/edit/:accountId/customTables`}
                component={CustomTables}
                exact
              />
              <Route
                path={`/home/accounts/edit/:accountId/XMLTagMapping`}
                component={XMLTagMapping}
                exact
              />
              <Route
                path={`/home/accounts/edit/:accountId/FTPSettings`}
                component={FTPSettings}
                exact
              />
              <Route
                path={`/home/accounts/edit/:accountId/Procedures`}
                component={ProcedureBuilder}
                exact
              />
              <Route
                path={`/home/accounts/edit/:accountId/Telephony`}
                component={Telephony}
                exact
              />
              <Route
                path={`/home/accounts/edit/:accountId/FormList`}
                component={FormList}
                // component={() => (
                //   <FormList
                //     m={-4}
                //     switchTab={switchTab}
                //     pageTitle={pageTitle}
                //     pageSubTitle={pageSubTitle || undefined}
                //   />
                // )}
                exact
              />
              <Route
                path={`/home/accounts/edit/:accountId/AddForm`}
                component={AddEditForm}
                exact
              />
              <Route
                path={`/home/accounts/edit/:accountId/EditForm/:formId`}
                component={AddEditForm}
                exact
              />
            </PageContent>
          </Switch>
        </Flex>
      </MainContent>
      {showImportAccount && (
        <ImportAccount
          showPanel={showImportAccount}
          togglePanel={toggleImportAccount}
          account={importAccountName}
          importAccountId={accountId || 0}
        />
      )}
    </>
  );
}

export default AddEditAccount;
