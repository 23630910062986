import { useQuery, QueryResult } from 'react-query';
import { getFormDetails } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { Forms } from 'types/forms';

async function fetchFormDetails({ formId }: TVariables): Promise<TResult> {
  const response = await fetch(getFormDetails(formId), {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type TResult = Forms | undefined;

export type TError = { message: string };
export type TVariables = {
  formId: number;
};

function useGetFormDetails(formId?: number): QueryResult<TResult, TError> {
  return useQuery(
    ['get_form_details', formId],
    async () => {
      if (formId)
        return await fetchFormDetails({
          formId,
        });
    },
    {
      // staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetFormDetails };
