import React, {
  ReactElement,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { Stack, Grid, Checkbox, Button } from 'ui/components';
import {
  SlidePanel,
  SearchInput,
  Card,
  CardHeader,
  CardBody,
  FormInput,
  FormSelect,
  EmptyState,
  Loader,
} from 'ui/patterns';
import NoDataImage from 'assets/images/NoDataImage';
import { checkBoxList } from './checkBoxList';
import {
  useAccountServices,
  useAccountStatus,
  useGetPublishedProcedures,
  useSaveCopyAccount,
  useGetFtpSettings,
} from 'hooks';
import { showErrorToast, showSuccessToast } from 'utils/showToast';
import { confirmAlert } from 'utils/confirm-alert';

type CopyAccountProps = {
  showPanel: boolean;
  togglePanel: () => void;
  account: any;
};

type ProcedureItemType = {
  ProcedureId: number;
  ProcedureName: string;
};

type ProcedureType = Array<ProcedureItemType> | undefined;

type CopiedDataItemType = {
  id: number;
  label: string;
  name: string;
};

type CopiedDataType = Array<CopiedDataItemType>;

export default function CopyAccount({
  showPanel,
  togglePanel,
  account,
}: CopyAccountProps): ReactElement {
  const history = useHistory<{ ID: number }>();
  const { data: accountStatuses, isLoading: isStatusLoading } =
    useAccountStatus();
  const { data: accountServices, isLoading: isServiceLoading } =
    useAccountServices();
  const { data: publishedProcedures, isLoading: isProcedureLoading } =
    useGetPublishedProcedures(account?.Id);

  const { data: ftpInfo, isLoading: isGetLoading } = useGetFtpSettings(
    account?.Id,
  );
  const isLoading =
    isStatusLoading || isServiceLoading || isProcedureLoading || isGetLoading;

  let interval: ReturnType<typeof setTimeout>;

  const startInterval = () => {
    let index = 0;
    interval = setInterval(() => {
      if (index < selectedCopiedData.length) {
        setLoadingText(`Copying ${selectedCopiedData[index].label}`);
      } else if (
        selectedProcedure &&
        selectedProcedure.length > 0 &&
        loadingText !== 'Finalizing Account Copy'
      ) {
        setLoadingText('Copying Procedures');
      } else {
        setLoadingText('Finalizing Account Copy');
      }
      index = index + 1;
    }, 500);
  };

  const [saveCopyAccount, { isLoading: isSaveCopyAccountLoading }] =
    useSaveCopyAccount();

  const { register, handleSubmit, reset, control, errors } = useForm();

  const [selectedProcedure, setSelectedProcedure] = useState<ProcedureType>([]);
  const [procedures, setProcedures] = useState<ProcedureType>([]);

  const [selectedCopiedData, setSelectedCopiedData] = useState<CopiedDataType>(
    [],
  );
  const [copiedData, setCopiedData] = useState<CopiedDataType>([
    ...checkBoxList,
  ]);

  useEffect(() => {
    if (publishedProcedures && publishedProcedures?.length > 0)
      setProcedures([...publishedProcedures]);
  }, [publishedProcedures]);

  const [searchValue, setSearchValue] = useState<string>();

  useEffect(() => {
    reset({
      AccountServiceId: account?.AccountServiceId,
      StatusId: account?.StatusId,
      AnswerScript: account?.AnswerScript,
      Name: account?.Name ? `Copy of ${account?.Name}` : '',
    });
  }, [account, reset]);

  const [loadingText, setLoadingText] = useState<string>('');

  function resetForm() {
    reset({
      CID: '',
      SID: '',
      PID: '',
      AccountServiceId: 0,
      StatusId: 0,
      AnswerScript: '',
      Name: '',
    });
    setSearchValue('');
    setSelectedProcedure([]);
    setProcedures(publishedProcedures);
    setSelectedCopiedData([]);
    setCopiedData(checkBoxList);
  }

  const copyAccountActions = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          togglePanel();
          resetForm();
        }}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit(onSubmit)}
        disabled={isSaveCopyAccountLoading}
      >
        Copy Account
      </Button>
    </>
  );

  const accountName = account?.AccountNameAndNumber || '';

  const copyAccount = (data: any) => {
    const procedureList =
      selectedProcedure && selectedProcedure.map(item => item.ProcedureId);

    const unselectedData = copiedData.filter(copyItem => {
      return (
        selectedCopiedData &&
        selectedCopiedData.filter(selectedItem => {
          return copyItem.id === selectedItem.id;
        }).length === 0
      );
    });

    //checked checkbox data
    const selectedDataObj = selectedCopiedData.reduce(
      (dataObj, item) => ({ ...dataObj, [item.name]: true }),
      {},
    );

    //unchecked data
    const otherDataObj =
      unselectedData &&
      unselectedData.reduce(
        (dataObj, item) => ({ ...dataObj, [item.name]: false }),
        {},
      );
    startInterval();
    saveCopyAccount(
      {
        ...data,
        ...selectedDataObj,
        ...otherDataObj,
        AccountId: account?.Id,
        ProcedureIds: procedureList,
      },
      {
        onSuccess: mutationResult => {
          clearInterval(interval);
          setLoadingText('Finalizing Account Copy');
          setLoadingText('');
          togglePanel();
          resetForm();
          confirmAlert({
            message:
              'Account creation is successful, click OK to navigate to your new account.',
            buttons: [
              {
                label: 'Ok',
                onClick: () => {
                  history.push(
                    `/home/accounts/edit/${mutationResult.Id}/accountInfo`,
                    { ID: mutationResult.Id ? mutationResult.Id : 0 },
                  );
                },
                className: 'pr-btn-primary',
              },
              {
                label: 'Cancel',
                onClick: () => {
                  resetForm();
                },
              },
            ],
          });
        },
        onError: e => {
          clearInterval(interval);
          setLoadingText('');
          showErrorToast({ message: e.Message, autoClose: 10000 });
        },
      },
    );
  };

  function onSubmit(data: any) {
    const ftpChecked = selectedCopiedData.find(
      item => item.label === 'FTP Settings',
    );
    if (ftpChecked && ftpInfo?.IsEnable) {
      confirmAlert({
        buttonOrder: true,
        message:
          'WARNING: This process will copy SFTP settings to the new account. All documents finalized in the new account will send to the same server. Are you sure you want to include SFTP Settings in this account copy process?',
        buttons: [
          {
            label: 'Cancel',
            onClick: () => {
              //
            },
            className: 'pr-btn-secondary',
          },
          {
            label: 'No',
            onClick: () => {
              const selected = copiedData.find(item => item.id === 6);
              if (selected) handleSelectImportDataToCopy(selected);
              copyAccount(data);
            },
          },
          {
            label: 'Yes',
            onClick: () => {
              copyAccount(data);
            },
            className: 'pr-btn-primary',
          },
        ],
      });
    } else {
      copyAccount(data);
    }
  }

  const isProcedureChecked = (id: number) => {
    const item =
      selectedProcedure &&
      selectedProcedure.find(item => item.ProcedureId === id);
    if (item) {
      return true;
    } else return false;
  };

  const handleSelectProcedure = (procItem: {
    ProcedureId: number;
    ProcedureName: string;
  }) => {
    const index =
      selectedProcedure && selectedProcedure.length > 0
        ? selectedProcedure.findIndex(
            item => item.ProcedureId === procItem.ProcedureId,
          )
        : -1;
    if (index === -1) {
      selectedProcedure &&
        selectedProcedure.push({
          ProcedureId: procItem.ProcedureId,
          ProcedureName: procItem.ProcedureName,
        });
    } else if (index >= 0) {
      selectedProcedure && selectedProcedure.splice(index, 1);
    }

    if (selectedProcedure) setSelectedProcedure([...selectedProcedure]);
  };

  const isCheckedAllProcedures = () => {
    if (
      selectedProcedure?.length ===
      (procedures && procedures.length > 0 && procedures.length)
    )
      return true;
    else return false;
  };

  const handleSelectAllProcedures = () => {
    let allprocedures: { ProcedureId: number; ProcedureName: string }[] = [];
    const isSelect = isCheckedAllProcedures();
    if (!isSelect) {
      allprocedures =
        procedures && procedures.length > 0
          ? procedures.map(item => {
              return {
                ProcedureId: item.ProcedureId,
                ProcedureName: item.ProcedureName,
              };
            })
          : [];
    } else {
      allprocedures = [];
    }

    setSelectedProcedure([...allprocedures]);
  };

  const handleProcedureSearch = (value: string) => {
    let newProcedures:
      | { ProcedureId: number; ProcedureName: string }[]
      | undefined = [];
    newProcedures =
      publishedProcedures &&
      publishedProcedures.filter(item => {
        if (item.ProcedureName.toLowerCase().includes(value.toLowerCase()))
          return item;
        return false;
      });

    if (newProcedures) setProcedures([...newProcedures]);
    if (newProcedures?.length === 0) setSelectedProcedure([...newProcedures]);
    setSearchValue(value);
  };

  const isDataToCopyChecked = (id: number) => {
    const item =
      selectedCopiedData && selectedCopiedData.find(item => item.id === id);
    if (item) {
      return true;
    } else return false;
  };

  const handleSelectImportDataToCopy = (dataItem: CopiedDataItemType) => {
    const index =
      selectedCopiedData && selectedCopiedData.length > 0
        ? selectedCopiedData.findIndex(item => item.id === dataItem.id)
        : -1;
    if (index === -1) {
      selectedCopiedData &&
        selectedCopiedData.push({
          id: dataItem.id,
          label: dataItem.label,
          name: dataItem.name,
        });
    } else if (index >= 0) {
      selectedCopiedData && selectedCopiedData.splice(index, 1);
    }

    if (selectedCopiedData) setSelectedCopiedData([...selectedCopiedData]);
  };

  const isCheckedAllData = () => {
    if (
      selectedCopiedData?.length ===
      (copiedData && copiedData.length > 0 && copiedData.length)
    )
      return true;
    else return false;
  };

  //
  const handleSelectAllData = () => {
    let allDatas: CopiedDataItemType[] = [];
    const isSelect = isCheckedAllData();
    if (!isSelect) {
      allDatas =
        copiedData && copiedData.length > 0
          ? copiedData.map(item => {
              return {
                id: item.id,
                label: item.label,
                name: item.name,
              };
            })
          : [];
    } else {
      allDatas = [];
    }
    setSelectedCopiedData([...allDatas]);
  };

  return (
    <>
      <SlidePanel
        title={`Copy Account - ${accountName}`}
        actions={copyAccountActions()}
        onClose={() => {
          togglePanel();
          resetForm();
        }}
        open={showPanel}
        size="lg"
      >
        <Stack direction="y" gap={6}>
          <Box>
            <Text fontSize={2} color="text.body" fontWeight="medium" mb={2}>
              New Account Identification
            </Text>
            <Grid minColWidth="150px" gap={4}>
              <FormInput
                feedback={errors.CID?.message}
                validation={errors.CID ? 'error' : undefined}
                register={register}
                label="CID"
                type="text"
                required
                maxLength={4}
              />
              <FormInput
                feedback={errors.SID?.message}
                validation={errors.SID ? 'error' : undefined}
                register={register}
                label="SID"
                type="text"
                required
                maxLength={4}
              />
              <FormInput
                feedback={errors.PID?.message}
                validation={errors.PID ? 'error' : undefined}
                register={register}
                label="PID"
                type="text"
                required
                maxLength={4}
              />
              <Controller
                render={(props: {
                  onChange: (value: number) => void;
                  value: number;
                }) => {
                  return (
                    <FormSelect
                      value={props.value}
                      feedback={errors.AccountServiceId?.message}
                      validation={
                        errors.AccountServiceId?.message ? 'error' : undefined
                      }
                      label="Service Type"
                      options={accountServices}
                      onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                        const value = parseInt(e.currentTarget.value, 10);
                        props.onChange(value);
                      }}
                      required
                    />
                  );
                }}
                rules={{
                  required: 'The Service Type field is required.',
                  validate: {
                    invalid: value => {
                      if (Number.isNaN(value))
                        return 'The Service Type field is required.';
                    },
                  },
                }}
                defaultValue={''}
                name="AccountServiceId"
                control={control}
              />

              <Controller
                render={(props: {
                  onChange: (value: number) => void;
                  value: number;
                }) => {
                  return (
                    <FormSelect
                      value={props.value}
                      feedback={errors.StatusId?.message}
                      validation={
                        errors.StatusId?.message ? 'error' : undefined
                      }
                      label="Status"
                      options={accountStatuses}
                      onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                        props.onChange(parseInt(e.currentTarget.value, 10));
                      }}
                      required
                    />
                  );
                }}
                rules={{
                  required: 'The Status field is required.',
                  validate: {
                    invalid: value => {
                      if (Number.isNaN(value))
                        return 'The Status field is required.';
                    },
                  },
                }}
                defaultValue={''}
                name="StatusId"
                control={control}
              />
            </Grid>
            <Box
              mt={2}
              sx={{
                display: 'grid',
                gridGap: 4,
                gridTemplateColumns: '1fr 2fr',
              }}
            >
              <FormInput
                name="Name"
                register={register}
                label="Account Name"
                type="text"
                required
                feedback={errors.Name?.message}
                validation={errors.Name ? 'error' : undefined}
              />
              <FormInput
                name="AnswerScript"
                register={register}
                label="Answer Script"
                type="text"
                required
                feedback={errors.AnswerScript?.message}
                validation={errors.AnswerScript ? 'error' : undefined}
              />
            </Box>
          </Box>
          <Card>
            <CardHeader px={3} py={2}>
              <Flex justifyContent="space-between" alignItems="center">
                <Text
                  as="h5"
                  fontSize={2}
                  color="text.body"
                  fontWeight="medium"
                >
                  Data to Copy
                </Text>
                <Checkbox
                  label="Select All"
                  name="copyAccountAllDatas"
                  checked={isCheckedAllData()}
                  handleChange={handleSelectAllData}
                />
              </Flex>
            </CardHeader>
            <CardBody p={3}>
              <Grid minColWidth="200px" gap={4}>
                {checkBoxList &&
                  checkBoxList.map(dataItem => (
                    <Checkbox
                      key={dataItem.id}
                      label={dataItem.label}
                      name={`copyAccount${dataItem.label}`}
                      checked={isDataToCopyChecked(dataItem.id)}
                      handleChange={() =>
                        handleSelectImportDataToCopy(dataItem)
                      }
                    />
                  ))}
              </Grid>
            </CardBody>
          </Card>
          <Card>
            <CardHeader px={3} py={2}>
              <Flex justifyContent="space-between" alignItems="center">
                <Text
                  as="h5"
                  fontSize={2}
                  color="text.body"
                  fontWeight="medium"
                >
                  Select Procedures to Copy to this Account{' '}
                  {procedures && procedures?.length > 0 ? (
                    <Text as="small" fontWeight="regular">
                      {`(You have selected ${selectedProcedure?.length} out of ${procedures?.length} procedures)`}
                    </Text>
                  ) : null}
                </Text>
                <Stack direction="x" gap={3}>
                  <SearchInput
                    placeholder="Search Procedures"
                    value={searchValue}
                    handleChange={handleProcedureSearch}
                  />
                  <Checkbox
                    label="Select All"
                    checked={isCheckedAllProcedures()}
                    name="copyAccountAllProcedures"
                    handleChange={handleSelectAllProcedures}
                  />
                </Stack>
              </Flex>
            </CardHeader>
            <CardBody p={3} minHeight="250px" style={{ position: 'relative' }}>
              <Grid minColWidth="200px" gap={4} alignItems="flex-start">
                {isLoading ? <Loader /> : null}
                {procedures && procedures.length > 0 ? (
                  procedures.map(procItem => (
                    <Checkbox
                      key={procItem.ProcedureId}
                      label={procItem.ProcedureName}
                      name={`copyAccount${procItem.ProcedureName}`}
                      checked={isProcedureChecked(procItem.ProcedureId)}
                      handleChange={() => handleSelectProcedure(procItem)}
                    />
                  ))
                ) : (
                  <EmptyState>
                    <NoDataImage feedback="No data available" />
                  </EmptyState>
                )}
              </Grid>
            </CardBody>
          </Card>
        </Stack>
      </SlidePanel>
      {(isSaveCopyAccountLoading || loadingText) && (
        <Loader text={`${loadingText}`} />
      )}
    </>
  );
}
